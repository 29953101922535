<template>
  <div class="page">
    <van-row>
      <van-col span="3" @click="back()"
        ><div class="back-btn"><van-icon name="arrow-left" />返回</div></van-col
      >
      <van-col span="21">
        <van-search
          v-model="keyword"
          placeholder="请输入搜索关键词"
          ref="keyword"
        ></van-search>
      </van-col>
    </van-row>
    <van-row class="title">
      <van-col span="24">成员列表</van-col>
    </van-row>
    <div class="person" v-for="person in personList" :key="person.code">
      <van-row>
        <van-col span="4"
          ><van-image
            class="photo"
            width="60"
            height="85"
            :src="person.photoPath"
          ></van-image
        ></van-col>
        <van-col span="16">
          <van-row>
            <van-col span="24">
              <div class="tips color-m" v-if="person.difficulty === 1">
                办理简单(资料符合条件)
                <span class="detail" @click="matchDetail(person.code)"
                  >查看明细</span
                >
              </div>
              <div
                class="tips color-s1"
                v-if="person.difficulty > 1 && person.difficulty < 100"
              >
                办理困难(不符合要求条件)
                <span class="detail" @click="matchDetail(person.code)"
                  >查看明细</span
                >
              </div>
              <div class="tips color-s2" v-if="person.difficulty >= 100">
                无法办理(不符合强制条件)
                <span class="detail" @click="matchDetail(person.code)"
                  >查看明细</span
                >
              </div>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="12" class="text">姓名：{{ person.name }}</van-col>
            <van-col span="12" class="text">年龄：{{ person.age }}</van-col>
          </van-row>
          <van-row>
            <van-col span="12" class="text"
              >性别：{{ person.genderName }}</van-col
            >
            <van-col span="12" class="text"
              >民族：{{ person.nationName }}</van-col
            >
          </van-row>
          <van-row>
            <van-col span="10" offset="1"
              ><van-button
                :color="COLOR_M"
                icon="edit"
                size="mini"
                block
                @click="personEdit(person.code)"
                >编辑</van-button
              ></van-col
            >
            <van-col span="10" offset="1"
              ><van-button
                type="danger"
                icon="delete"
                size="mini"
                block
                @click="deletePerson(person.code)"
                >删除</van-button
              ></van-col
            >
          </van-row>
        </van-col>
        <van-col span="4">
          <van-button
            :color="COLOR_M"
            class="btn"
            icon="passed"
            size="mini"
            v-if="person.difficulty < 100"
            block
            @click="chosePerson(person.code)"
            >选择</van-button
          >
          <van-button
            :color="COLOR_M"
            class="btn"
            icon="passed"
            size="mini"
            v-if="person.difficulty > 100"
            block
            disabled
            >选择</van-button
          >
        </van-col>
      </van-row>
    </div>
    <div class="operate">
      <van-row>
        <van-col span="24">
          <van-button
            class="btn"
            icon="plus"
            :color="COLOR"
            size="small"
            @click="createPerson"
            >新增成员</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Share from '../../common/Share.vue'
import { Search, Image, Popup, Icon, Tag } from 'vant'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Share: Share,
    Loading: Loading,
    [Image.name]: Image,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Tag.name]: Tag
  },
  data () {
    return {
      loadingShow: false,
      shareShow: false,
      module: '',
      taskCode: '',
      profile: '',
      personList: []
    }
  },
  mounted () {
    document.title = '成员匹配'
    var query = this.$route.query
    this.business = query.business
    this.taskCode = query.taskCode
    this.profile = query.profile
    this.retrievePersonMatchList()
    this.$refs.share.default('main')
  },
  methods: {
    async retrievePersonMatchList () {
      this.loadingShow = true
      var pd = { taskCode: this.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/retrievePersonMatchList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.personList = res.data
      }
      if (this.personList.length <= 0) {
        this.createPerson()
      }
      this.loadingShow = false
    },
    personEdit (code) {
      var key = window.const.global.PEOPLE_PERSON_DTLEDIT_BACK
      window.sessionStorage.setItem(key, window.location.href)
      this.$router.push({ path: '/mde/people/person/dtlEdit', query: { personCode: code, business: this.business, profile: this.profile } })
    },
    chosePerson (code) {
      var key = window.const.global.MEMBER_PERSON_MATCH_CHOSE
      window.sessionStorage.setItem(key, code)
      this.back()
    },
    deletePerson (code) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除成员吗？'
      })
        .then(() => {
          // on confirm
          this.deletePersonExecute(code)
        })
    },
    async deletePersonExecute (personCode) {
      var pd = { personCode: personCode }
      this.loadingShow = true
      var that = this
      const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/deletePerson', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '删除成员',
          message: '删除成功'
        }).then(() => {
          // on close
          that.retrievePersonMatchList()
        })
      }
    },
    matchDetail (personCode) {
      window.sessionStorage.setItem('person_match_detail_back', window.location.href)
      this.$router.push({ path: '/mde/member/personMatchDetail', query: { personCode: personCode, taskCode: this.taskCode } })
    },
    createPerson () {
      var key = window.const.global.PEOPLE_PERSON_DTLEDIT_BACK
      window.sessionStorage.setItem(key, window.location.href)
      this.$router.push({ path: '/mde/member/personCreate', query: { profile: this.profile } })
    },
    back () {
      var url = window.sessionStorage.getItem(window.const.global.MEMBER_PERSON_MATCH_BACK)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 50px;
}
.back-btn {
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
}
.title {
  height: 25px;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  color: #fff;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#fff));
}
.person {
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  .photo {
    margin-top: 3px;
  }
  .text {
    text-align: left;
    padding-left: 10px;
    height: 20px;
    line-height: 20px;
  }
  .btn {
    height: 100%;
  }
  .tips {
    margin: 0px auto 3px auto;
    padding: 5px 0px;
    width: 90%;
    text-align: left;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    .detail {
      border: 1px solid #eee;
      border-radius: 3px;
      padding: 1px 3px;
      margin: 2px;
    }
  }
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 40px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
}
.shareImg {
  width: 90%;
}
</style>
